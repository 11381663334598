
<template>
  <CRow>
    <CCol sm>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol sm="12" md="12" xl="6">
              <form @submit.prevent="saveScreen" id="formscreen">
                <CInput
                  label="Nom"
                  placeholder="Nom de l'écran"
                  v-model="screen.name"
                  invalid-feedback="a name is required"
                  :is-valid="checkName"
                ></CInput>

                <div class="form-group">
                  <label for="timer_screen">Timer (s)</label>
                  <input
                    v-model="screen.timer"
                    id="timer_screen"
                    name="timer"
                    type="number"
                    step="0.5"
                    min="0.5"
                    class="form-control"
                  />
                </div>

                <CTextarea
                  label="Bandeau"
                  placeholder="Bandeau de bas d'écran"
                  v-model="screen.bandeau"
                ></CTextarea>
                <div role="group" class="form-group" data-children-count="1">
                  <div class="d-flex justify-content-between">
                    <label for="uid-mqjopxcj4ef" class="">En diffusion</label>
                    <CSwitch
                      variant="3d"
                      color="success"
                      :checked.sync="screen.online"
                    />
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div class="text-danger">{{ errors.message }}</div>
                  <div v-if="!loading" class="text-right">
                    <button class="btn btn-primary" type="submit">Save</button>
                  </div>
                  <div v-else class="text-right">
                    <button class="btn btn-primary" type="submit" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Saving...
                    </button>
                  </div>
                </div>
                <CAlert :show.sync="successSaved" color="success" fade>
                  Changement sauvegardé avec succés
                </CAlert>
              </form>
            </CCol>
            <CCol sm="12" md="12" xl="6">
              <div>ID raspbery PI : {{ screen.id_rasb }}</div>
              <!--div class="mt-4">
                <p>Vidéo globale :</p>
                <p>Statut : {{ screen.fullvideo_status }}</p>
                <p>Lien : {{globalURL}}{{ screen.fullvideo }}</p>
                <p v-if="screen.fullvideo_status != 'ongoing' && screen.fullvideo_status != 'ready'">
                  <button class="btn btn-primary" @click="generateVideo">
                    Generer video
                  </button>
                </p>
              </div-->
            </CCol>
          </CRow>
          <CRow v-if="screen.medias">
            <p class="titreMediaEcran">Médias diffusé sur l'écran :</p> 
            <GalerieScreen
              v-bind:medias="screen.medias"
              :screen_id="screen.id"
              :key="reloadGalerieScreen"
            ></GalerieScreen>
          </CRow>
          <div class="text-right">
            <button class="btn btn-primary" @click="popupGalerie = true">
              Ajouter des médias
            </button>
          </div>
          <CRow v-if="popupGalerie">
            <Galerie
              @closeGalerie="closePopupGalerie"
              :screen_id="screen.id"
            ></Galerie>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import GalerieScreen from "@/components/GalerieScreen.vue";
import Galerie from "@/components/Galerie.vue";

export default {
  name: "Screen",
  components: {
    GalerieScreen,
    Galerie,
  },
  data() {
    return {
      id: this.$route.params.id,
      screen: "",
      loading: false,
      errors: { message: "" },
      isActive: true,
      successSaved: 0,
      popupGalerie: false,
      reloadGalerieScreen: 1,
      globalURL: process.env.VUE_APP_MEDIA_URL
    };
  },
  async created() {
    console.log("created before");
    let rep = await window.axios.get("/api/screen/" + this.id);
    this.screen = rep.data.screen;

    /*window.axios.get("/api/screen/" + id_screen).then((rep) => {
      self.screen = rep.data.screen;
      console.log('done')
      console.log(self.screen);
      self.loading = false
    })
    .catch(error =>{
      console.log(error)
      self.loading = false
    })
    .finally(()=>{
      self.loading = false
      console.log('finnaly', this.loading)
    })*/
  },
  methods: {
    async closePopupGalerie() {
      this.popupGalerie = false;
      //this.$forceUpdate()
      let rep = await window.axios.get("/api/screen/" + this.id);
      this.screen = rep.data.screen;
      this.reloadGalerieScreen++;
    },

    async saveScreen() {
      console.log("save...");
      this.loading = true;
      try {
        let rep = await window.axios.put("/api/screen/" + this.id, this.screen);
        this.screen = { ...this.screen, ...rep.data.screen };
        this.successSaved = 1;
        this.errors.message = "";
      } catch (err) {
        console.log(err.response.data);
        this.errors = err.response.data;
      } finally {
        this.loading = false;
      }
    },
    checkName() {
      return this.screen.name == "" ? false : true;
    },
    async generateVideo() {
      console.log("generate");
      try {
        let rep = await window.axios.post("/api/screen/"+ this.id+"/generatefullvideo" );
        this.screen.fullvideo_status = 'ongoing';
        console.log(rep);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 2rem;
}
.form-control.is-valid {
  border-color: #d8dbe0 !important;
  background-image: none !important;
}
.titreMediaEcran{
  margin-left: 15px;
    margin-top: 30px;
}
</style>
