<template>
  <!--div v-for="m in medias" :key="m.id">{{ m.file_name }} n</div-->
  <div>
    
    <draggable v-model="allMedias" @end="onEnd" id="containerMedias">
      <transition-group type="transition">
        <div
          v-for="element in allMedias"
          :key="element.id"
          class="cursorMove blockImg col-lg-2"
        >
          <span v-if="element.type == 'picture'">
            <img :src="element.url" class="img-fluid" />
          </span>
          <span v-else>
            <video
              width="145"
              controls="controls"
              preload="metadata"
            >
              <source :src="element.url + '#t=0.1'" type="video/mp4" />
            </video>
          </span>
          <!--span class="removeMedia" @click="removeMedia(element.id)">
            <CIcon name="cil-x-circle" />
          </span-->
          <button type="button" class="btn btn-danger supBtn" @click="removeMedia(element.id)">Supprimer de l'écran</button>
        
          <div class="blockDate">
            <p>Date début diffusion</p>
            <datepicker :bootstrap-styling="true" v-model="element.start_diff" :clear-button="true" @input='setStartDiff(element.id)' >
            </datepicker>
          </div>
          
          <div class="blockDate">
            <p>Date fin diffusion</p>
             <datepicker :bootstrap-styling="true" v-model="element.end_diff" :clear-button="true" @input='setEndDiff(element.id)' ></datepicker>
          </div>
          <!--vc-date-picker v-model="element.end_diff" @dayclick='setEndDiff(element.id)' :first-day-of-week="2" locale="fr" mode="date" is24hr>
            <template v-slot="{ inputValue, inputEvents }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                v-on="inputEvents" clearable
              />
            </template>
          </vc-date-picker-->

          </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Datepicker from 'vuejs-datepicker';
export default {
  name: "GalerieScreen",
  components: {
    draggable,
    Datepicker
  },
  props: {
    medias: Array,
    screen_id: Number,
  },
  data: function () {
    return {
      // allMedias: [...this.medias]
      allMedias: [],
      
      masks: {
        input: 'YYYY-MM-DD h:mm A',
      }
    };
  },
  computed:{
    background(m){
        return process.env.VUE_APP_MEDIA_URL+m
  }
},
  created() {
    //created clean data
    console.log(this.medias, "here vv");
    console.log(process.env.VUE_APP_MEDIA_URL)

    for (var i = 0; i < this.medias.length; i++) {
      this.allMedias[i] = {
        id: this.medias[i].id,
        file_name: this.medias[i].file_name,
        ordre: this.medias[i].pivot.ordre,
        url: process.env.VUE_APP_MEDIA_URL+this.medias[i].file_name,
        type: this.medias[i].type,
        start_diff: this.medias[i].pivot.start_diff,
        end_diff: this.medias[i].pivot.end_diff,
      };
    }
  },
  methods: {
    onEnd() {
      console.log(this.allMedias);
      //screen/{screen_id}/ordermedia
      const self = this;
      window.axios
        .put("/api/screen/" + self.screen_id + "/ordermedia", {
          medias: self.allMedias,
        })
        .then((rep) => {
          console.log("done", rep);
        });
    },
    removeMedia(id) {
      const self = this;
      window.axios
        .delete("/api/screen/" + self.screen_id + "/" + id)
        .then(() => {
          self.allMedias = self.allMedias.filter(function (value) {
            return value.id != id;
          });
        });
    },
    setStartDiff(media){
      const found = this.allMedias.find(element => element.id == media)
      
      var date_elem = ""
      if(found.start_diff && found.start_diff !== "")
        date_elem = found.start_diff.toISOString().slice(0, 10) + ' 00:00:01'
        
      const self = this;
       window.axios
        .put("/api/updatestartdiff/" + self.screen_id + "/" + found.id, {
          start_diff: date_elem,
        })
        .then((rep) => {
          console.log("done", rep);
        });
    },
    setEndDiff(media){
      
      const found = this.allMedias.find(element => element.id == media)
      
      var date_elem = ""
      if(found.end_diff && found.end_diff !== "")
        date_elem = found.end_diff.toISOString().slice(0, 10) + ' 00:00:01'
        
      const self = this;
       window.axios
        .put("/api/updateenddiff/" + self.screen_id + "/" + found.id, {
          end_diff: date_elem,
        })
        .then((rep) => {
          console.log("done", rep);
        });
      
    }
  },
};
</script>

<style scoped>
#containerMedias span {
  display: flex;
  flex-wrap: wrap;
}

.cursorMove:hover {
  cursor: move;
}

#containerMedias img {
  padding: 10px;
}

.blockImg {
  /*max-width: 350px;*/
  text-align: center;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

video{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.blockDate{
  margin-top:10px;
}
.blockDate p{
  margin-bottom: 2px;
}

.supBtn{
  margin-bottom: 15px;
}
</style>
