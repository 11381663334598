<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="d-flex justify-content-between">
            <h3>Votre galerie</h3>
            <button class="btn btn-info btn-sm" @click="close">Fermer</button>
          </div>
          <CRow>
            <CCol lg>
              <p>Selectionner les médias à ajouter à votre écran</p>
              <div class="row">
                <div v-for="m in galerie" :key="m.id" class="col-lg-3 blockM">
                  <label>
                    <div v-if="m.type == 'picture'" class="file__thumbnail">
                      <img
                        :src="urlMedia(m.file_name)"
                        class="thumbnail__thumbnail"
                        :class="selectedMedias.includes(m.id) ? 'active' : ''"
                      />
                    </div>
                    <div v-else>
                      <div>
                        <video
                          width="145"
                          controls="controls"
                          preload="metadata"
                          :class="selectedMedias.includes(m.id) ? 'active' : ''"
                        >
                          <source
                            :src="urlMedia(m.file_name) + '#t=0.1'"
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div>
                        <p>select video</p>
                      </div>
                    </div>
                    
                    <input
                      type="checkbox"
                      :id="'chk_' + m.id"
                      :value="m.id"
                      v-model="selectedMedias"
                    />
                    
                  </label>

                  <button type="button" class="btn btn-danger" @click="deleteMedia(m.id)">Supprimer</button>
                </div>
              </div>
              <div v-if="!loadingAddMedia" class="text-right">
                <button
                  class="btn btn-primary"
                  @click="addSelection"
                  :disabled="selectedMedias.length == 0"
                >
                  Ajouter
                </button>
              </div>
              <div v-else class="text-right">
                <button class="btn btn-primary" disabled>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving...
                </button>
              </div>
            </CCol>
            <CCol lg>
              <p>
                Déposer vos fichier dans la zones ci dessous pour ajouter de
                nouveaux médias à votre galerie.
              </p>

              <div id="file-drag-drop">
                <form ref="fileform">
                  <span class="drop-files">Drop the files here!</span>

                  <!--progress
                        max="100"
                        :value.prop="uploadPercentage"
                      ></progress-->
                </form>
                <CProgress
                  :value="uploadPercentage"
                  color="success"
                  animated
                  show-value
                  style="height: 15px"
                  class="mt-1"
                  v-if="uploadPercentage > 0"
                />
              </div>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Galerie",

  props: {
    screen_id: Number,
  },
  data: function () {
    return {
      galerie: [],
      dragAndDropCapable: false,
      files: [],
      uploadPercentage: 0,
      selectedMedias: [],
      loadingAddMedia: false,
    };
  },
  async created() {
    let rep = await window.axios.get("/api/medias");

    this.galerie = rep.data.medias;
    console.log("here -> ", this.galerie);
  },
  mounted() {
    /*
        Determine if drag and drop functionality is capable in the browser
      */
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    /*
        If drag and drop capable, then we continue to bind events to our elements.
      */
    if (this.dragAndDropCapable) {
      /*
          Listen to all of the drag events and bind an event listener to each
          for the fileform.
        */
      [
        "drag",
        "dragstart",
        "dragend",
        "dragover",
        "dragenter",
        "dragleave",
        "drop",
      ].forEach(
        function (evt) {
          /*
            For each event add an event listener that prevents the default action
            (opening the file in the browser) and stop the propagation of the event (so
            no other elements open the file in the browser)
          */
          this.$refs.fileform.addEventListener(
            evt,
            function (e) {
              e.preventDefault();
              e.stopPropagation();
            }.bind(this),
            false
          );
        }.bind(this)
      );

      /*
          Add an event listener for drop to the form
        */
      this.$refs.fileform.addEventListener(
        "drop",
        function (e) {
          /*
            Capture the files from the drop event and add them to our local files
            array.
          */
          console.log("file dropped");
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            if (
              e.dataTransfer.files[i].type == "image/jpeg" ||
              e.dataTransfer.files[i].type == "image/png" ||
              e.dataTransfer.files[i].type == "video/mp4" ||
              e.dataTransfer.files[i].type == "image/avi"
            )
              this.files.push(e.dataTransfer.files[i]);
          }
          console.log(this.files);
          this.submitFiles();
        }.bind(this)
      );
    }
  },
  methods: {
    urlMedia(url){
      return process.env.VUE_APP_MEDIA_URL+url
    },
    close() {
      this.$emit("closeGalerie", false);
    },
    async addSelection() {
      console.log(this.selectedMedias);
      this.loadingAddMedia = true;
      try {
        await window.axios.post("/api/mediascreen/" + this.screen_id, {
          medias: this.selectedMedias,
        });
      } catch (err) {
        console.log("erreur add media");
      } finally {
        this.loadingAddMedia = false;
      }
    },
    toggleMedia() {
      //console.log(this.selectedMedias)
    },
   deleteMedia(id){
      const self = this;
      window.axios
        .delete("/api/medias/" + id)
        .then(() => {
          console.log('deleted');
          window.axios.get("/api/medias").then((rep)=>{
            self.galerie = rep.data.medias;
          })

          
        });
    },
    /*
        Determines if the drag and drop functionality is in the
        window
      */
    determineDragAndDropCapable() {
      /*
          Create a test element to see if certain events
          are present that let us do drag and drop.
        */
      var div = document.createElement("div");

      /*
          Check to see if the `draggable` event is in the element
          or the `ondragstart` and `ondrop` events are in the element. If
          they are, then we have what we need for dragging and dropping files.

          We also check to see if the window has `FormData` and `FileReader` objects
          present so we can do our AJAX uploading
        */
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    submitFiles() {
      /*
          Initialize the form data
        */
      let formData = new FormData();

      /*
          Iteate over any file sent over appending the files
          to the form data.
        */
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        formData.append("files[" + i + "]", file);
      }
      let self = this;
      /*
          Make the request to the POST /file-drag-drop-instant URL
        */
      window.axios
        .post("/api/medias", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            );
          }.bind(this),
        })
        .then(function (rep) {
          console.log("SUCCESS!!");
          console.log(rep.data);
          self.galerie = null;
          self.galerie = rep.data.medias;
        })
        .catch(function () {
          console.log("FAILURE!!");
        })
        .finally(() => {
          self.files = [];
          self.uploadPercentage = 0;
        });
    },
  },
};
</script>

<style scoped>
#file-drag-drop form {
  display: block;
  height: 400px;

  background: rgb(233, 230, 230);
  margin: auto;
  margin-top: 40px;
  text-align: center;
  line-height: 400px;
  border-radius: 4px;
  border-radius: 4px;
  border: 3px #3c4b64 dashed;
}
#file-drag-drop form:hover {
  border: 3px #3ab5af dashed;
}

progress {
  width: 400px;
  margin: auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.file__thumbnail {
  /*height: 150px;*/
  overflow: hidden;
  display: flex;
}

.thumbnail__thumbnail {
  width: 100%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

input[type="checkbox"] {
  visibility: hidden;
}

img.active,
video.active {
  border: 6px solid rgb(0, 156, 0);
}

img:hover {
  cursor: pointer;
}

.blockM{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 35px;
}
</style>
